import { useEffect } from 'react';
import './App.css';

function App() {

  async function trigger() {
    const iframe = document.getElementById('myIFrame');
    const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
    console.log({ iframeDocument });
    iframeDocument.body.addEventListener('keydown', Handler);
    iframeDocument.body.addEventListener('click', Handler);
  };

  function Handler(e) {
      const { value = '', id = undefined } = e.target || {};
      console.log({ id, value });
  };

  useEffect(() => {
    setTimeout(() => {
      trigger();
    }, 5000);
  }, []);

  return (
    <div>
      <iframe id="myIFrame" src="https://recorder.loadtester.io/" width="100%" height="1000px" />
    </div>
  );
}

export default App;
